<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">

            </search>
        </div>
        <div class="mt-3">
            <el-table
                    :data="tableData"
                    stripe
                    border
                    @sort-change="sortChange"
                    :default-sort="page.sort"
                    style="width: 100%">
                <el-table-column v-for="item in showFields"
                                 :prop="item.name"
                                 :key="item.name"
                                 :label="item.title"
                                 :sortable="item.sortable"
                                 :width="item.width">
                    <template slot-scope="scope">
                        <el-tag v-if="item.name==='active'" :type="scope.row.active===1?'success':'warning'">
                            {{scope.row.active===1?'正常':(scope.row.active===2?'注销中':'禁用')}}
                        </el-tag>
                        <span v-else-if="item.name==='create_time'">
                            <format-time :time="scope.row.create_time"></format-time>
                        </span>
                        <span v-else-if="item.name==='member_time'">
                            <format-time :time="scope.row.member_time"></format-time>
                        </span>
                        <span v-else>{{scope.row[item.name]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                >
                    <template slot-scope="scope">
                        <el-button @click="editUser(scope)" size="mini" v-access="{method:'put',url:baseUrl}" icon="el-icon-edit"></el-button>
                        <el-button icon="el-icon-info" v-access="{url:'/app/chart',method:'menu'}" @click="userInfo(scope.row.phone)" size="mini"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                    @size-change="sizeChange"
                    @current-change="pageChange"
                    :current-page="page.pageNumber"
                    :page-sizes="[10, 15, 30, 50]"
                    :page-size="page.pageSize"
                    :background="true"
                    layout="sizes,prev, pager,next,total"
                    :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
    import F from '@/fields/fields';
    import search from "@/components/common/search";
    import FormatTime from '@/components/common/format-time';
    export default {
        name: "userApp",
        components: {
            search,FormatTime
        },
        mounted() {

        },
        data: function () {
            return {
                baseUrl:'/manage/mail/user',
                page: {
                    search: {},
                    sort: {prop: 'created', order: 'descending'},
                    total: 1,
                    pageSize: 10,
                    pageNumber: 1,
                },
                tableData: [],
                actionName: '',
                index:null,
                form: {
                    action_name: '',
                    show: false,
                    title: '',
                    data: {},
                    fields: [],
                    rules: {},
                }
            }
        },
        computed: {
            searchFields: function () {
                return F.search('mailBox', 'search');
            },
            showFields: function () {
                return F.show('mailBox', 'show');
            }
        },
        methods: {
            condition: function (search) {
                this.page.search = search;
                this.page.pageNumber = 1;
                this.search();
            },
            search: function () {
                this.$axios.get(this.baseUrl, {
                    params: this.page,
                }).then(data => {
                    data = data.data;
                    this.page.total = data.data.total;
                    this.page.pageNumber = data.data.pageNumber;
                    this.page.pageSize = data.data.pageSize;
                    this.tableData = data.data.data;
                });
            },
            submit: function (data) {
                // console.log(data);
                if (data.type === 'cancel') {
                    this.form.show = false;
                    return;
                }
                switch (this.actionName) {
                    case 'editUser':
                        this.$axios.put(this.baseUrl, data.data).then(userData => {
                            this.$message.success('修改成功!');
                            // console.log(userData);
                            this.tableData.splice(this.form.index,1,userData.data);
                            // this.tableData.unshift(userData.data);
                        });
                        break;
                }
                this.form.show = false;
            },
            editUser: function (data) {
                this.actionName = 'editUser';
                let form = F.get('mailBox', 'edit');
                form.index=data.$index;
                form.data = {
                    username: data.row.username,
                    active: data.row.active,
                }
                form.show = true;
                this.form = form;
            },
            userInfo:function(phone){
                this.$router.push({
                    path:'/app/chart/'+phone,
                });
            },
            sortChange: function (column) {
                if (!column.order) return;
                this.page.sort = {prop: column.prop, order: column.order};
                this.search();
            },
            sizeChange: function (size) {
                this.page.pageSize = size;
                this.search();
            },
            pageChange: function (page) {
                this.page.pageNumber = page;
                this.search();
            },
        }
    }
</script>
<style scoped>

</style>
